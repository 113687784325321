// export const baseUrl = 'http://machinedesignapp.test.pingchuanwangluo.cn/api';
export const baseUrl = 'https://www.hscax.com/api';
// export const baseUrl = '/api';
// export const baseUrl = 'http://192.168.88.169:65217';
export default {
  getHomePageList: baseUrl + '/homePage/getHomePageList',// 登陆
  getOnly: baseUrl + '/OperationalData/getOnly',// 获取首页运营数据
  getTaskType: baseUrl + '/homePage/getTaskType',// 获得任务分类
  getTaskDetails: baseUrl + '/taskHall/getTaskDetails',// 获得任务详情
  getProvince: baseUrl + '/homePage/getProvince',// 获得省份
  getCity: baseUrl + '/homePage/getCity',// 获得城市
  getArea: baseUrl + '/domestic/getProvince',// 获得区
  getTaskHallList: baseUrl + '/taskHall/getTaskHallList',// 任务大厅查询列表
  insertDemand: baseUrl + '/taskHall/insertDemand',// 发布需求
  getTasks: baseUrl + '/taskHall/getTasks',// 获得最新任务列表
  getQualifications: baseUrl + '/taskHall/getQualifications',// 判断是否有接单资格
  insertBidding: baseUrl + '/taskHall/insertBidding',// 竞标
  getDesigners: baseUrl + '/designer/getDesigners',// 获得设计师列表
  designerSort: baseUrl + '/designer/designerSort',// 获得设计师列表（成交率排序）
  getDesignerDetails: baseUrl + '/designer/getDesignerDetails',// 获得设计师详情
  attention: baseUrl + '/designer/attention',// 关注/取消关注
  getDesignerCase: baseUrl + '/designer/getDesignerCase',// 获得设计师案例
  insertDesigner: baseUrl + '/designer/insertDesigner',// 入驻设计师
  getCases: baseUrl + '/case/getCases',// 获得成功案例列表
  getCaseDetails: baseUrl + '/case/getCaseDetails',// 获得案例详情
  getWhetherDesigner: baseUrl + '/personalCenter/getWhetherDesigner',// 判断是否为设计师
  getMePublishTasks: baseUrl + '/personalCenter/getMePublishTasks',// 获得我发布的任务列表
  getMePublishTaskDetails: baseUrl + '/personalCenter/getMePublishTaskDetails',// 获得我发布的任务详情
  choiceOffer: baseUrl + '/personalCenter/choiceOffer',// 同意或拒绝报价
  getMeReceiveTasks: baseUrl + '/personalCenter/getMeReceiveTasks',// 获得我接收的任务列表
  getMeReceiveTaskDetails: baseUrl + '/personalCenter/getMeReceiveTaskDetails',// 获得我接收的任务详情
  editMeBidding: baseUrl + '/personalCenter/editMeBidding',// 修改我得报价
  upload: baseUrl + '/aLiYunOSS/upload',// 阿里云文件上传
  getTaskBiddingCount: baseUrl + '/taskHall/getTaskBiddingCount',// 获得任务参加报价人数
  editDemand: baseUrl + '/taskHall/editDemand',// 撤销任务
  register: baseUrl + '/pc/register',// 注册
  login: baseUrl + '/pc/login',// 登陆
  logout: baseUrl + '/pc/logout',// 退出登录
  sendSms: baseUrl + '/pc/sendSms',// 手机获取验证码
  forgetPassword: baseUrl + '/pc/forgetPassword',// 忘记密码
  getCustCare: baseUrl + '/homePage/getCustCare',// 获得客服
  
  createOrderZ: baseUrl + '/aliPay/createOrder',// 支付宝支付
  createOrderW: baseUrl + '/wxPay/createOrder',// 微信支付
  collection: baseUrl + '/designer/collection',// 收藏/取消
  getUserAccountDetails: baseUrl + '/personalCenter/getUserAccountDetails',// 用户详情
  
  insertBiddingDetails: baseUrl + '/personalCenter/insertBiddingDetails',// 添加竞价付款节点信息
  UserPayment: baseUrl + '/personalCenter/UserPayment',// 用户支付节点
  getBiddingDetails: baseUrl + '/personalCenter/getBiddingDetails',// 用户节点列表
  
  getBiddingPay: baseUrl + '/personalCenter/getBiddingPay',// 竞价获取订单信息
  ysePayment: baseUrl + '/personalCenter/ysePayment',// 用户确认打款
  successTaskImg: baseUrl + '/personalCenter/successTaskImg',// 成功上传文件
  createOtherOrder: baseUrl + '/aliPay/createOtherOrder',// 保证金
  
  getRotation: baseUrl + '/homePage/getRotation',// 获得轮播图
  getExplain: baseUrl + '/homePage/getExplain',// 获得说明信息
  getMaterials: baseUrl + '/material/getMaterials',// 获得素材列表
  getMaterialDetails: baseUrl + '/material/getMaterialDetails',// 获得素材详情
  getDrawingDetails: baseUrl + '/material/getDrawingDetails',// 获得图纸详情
  attentionDrawing: baseUrl + '/material/attentionDrawing',// 关注//取消关注素材
  getSoftwareType: baseUrl + '/material/getSoftwareType',// 获得软件分类列表
  getDrawingTypeOne: baseUrl + '/material/getDrawingTypeOne',// 获得图纸分类列表一级
  getDrawingTypeTwo: baseUrl + '/material/getDrawingTypeTwo',// 获得图纸分类列表二级
  getDrawingTypeThree: baseUrl + '/material/getDrawingTypeThree',// 获得图纸分类列表三级
  intergralRecharge: baseUrl + '/material/intergralRecharge',// 获取积分充值列表
  paymentDownload: baseUrl + '/material/paymentDownload',// 支付下载
  insertMaterial: baseUrl + '/personalCenter/insertMaterial',// 上传素材
  getMaterialsM: baseUrl + '/personalCenter/getMaterials',// 获得我的素材列表
  getMaterialDetails1: baseUrl + '/personalCenter/getMaterialDetails',// 获得我的素材详情
  editMaterial: baseUrl + '/personalCenter/editMaterial',// 编辑/删除素材
  getMaterialDownloads: baseUrl + '/personalCenter/getMaterialDownloads',// 获得我下载记录
  getMeAttention: baseUrl + '/personalCenter/getMeAttention',// 获得我的收藏
  getUserDetails: baseUrl + '/personalCenter/getUserDetails',// 获得个人中心信息
  editUser: baseUrl + '/personalCenter/editUser',// 修改账号信息
  editDesigner: baseUrl + '/personalCenter/editDesigner',// 修改设计师信息
  editReal: baseUrl + '/personalCenter/editReal',// 实名认证
  getIntergralRecord: baseUrl + '/personalCenter/getIntergralRecord',// 获得积分币记录
  getMaterialRecord: baseUrl + '/personalCenter/getMaterialRecord',// 获得购买素材记录
  getCurriculumRecord: baseUrl + '/personalCenter/getCurriculumRecord',// 获得购买教育记录
  getDemandRecord: baseUrl + '/personalCenter/getDemandRecord',// 获得任务记录
  getWhetherDesigner: baseUrl + '/personalCenter/getWhetherDesigner',// 判断是否为设计师
  
  getTaskPay: baseUrl + '/taskHall/getTaskPay',// 获得任务支付赏金页面
  createIntegralOrder: baseUrl + '/aliPay/createIntegralOrder',// 支付宝积分充值
  getPaySu: baseUrl + '/taskHall/getPaySu',// 获得订单成功失败
  getBountyPay: baseUrl + '/personalCenter/getBountyPay',// 托管获得支付页面
  bountyPay: baseUrl + '/taskHall/bountyPay',// 支付托管赏金
  designerPay: baseUrl + '/personalCenter/designerPay',// 支付设计师赏金
  bountyPays: baseUrl + '/personalCenter/bountyPay',// 托管支付设计师赏金
  submitFile: baseUrl + '/personalCenter/submitFile',// 设计师提交任务附件
  submitCase: baseUrl + '/personalCenter/submitCase',// 设计师提交案例
  getDesignerPay: baseUrl + '/personalCenter/getDesignerPay',// 获得支付设计师赏金页面
  
  getUserDetails: baseUrl + '/personalCenter/getUserDetails',// 获得个人中心信息
  getMyProfit: baseUrl + '/personalCenter/getMyProfit',// 获得我的收益
  editUser: baseUrl + '/personalCenter/editUser',// 修改账号信息
  lookMessage: baseUrl + '/personalCenter/lookMessage',// 消息列表
  signIn: baseUrl + '/homePage/signIn',// 签到
  getSignIn: baseUrl + '/homePage/getSignIn',// 获得签到数据
  deleteBidding: baseUrl + '/personalCenter/deleteBidding',// 取消报价
  getGoodSoftware: baseUrl + '/designer/getGoodSoftware',// 获得擅长软件下拉框
  
  wxLogin: baseUrl + '/wxLogin/getCode',// 微信登录二维码
  qqLogin: baseUrl + '/qqLogin/getCode',// qq登录二维码
  wxCallback: baseUrl + '/wxLogin/wxCallback',// 微信回调
  readMessage: baseUrl + '/personalCenter/readMessage',// 已读
  editUserPhone: baseUrl + '/personalCenter/editUserPhone',// 绑定手机号
  requestPayment: baseUrl + '/personalCenter/requestPayment',// 请求打款
  
  // 论坛
  getForum: baseUrl + '/forum/getForum',// 获得论坛大厅列表
  getForumDetails: baseUrl + '/forum/getForumDetails',// 获得帖子详情
  publishForum: baseUrl + '/forum/publishForum',// 发表帖子
  publishComment: baseUrl + '/forum/publishComment',// 发表评论
  getForumType: baseUrl + '/forum/getForumType',// 获得论坛分类
  myForum: baseUrl + '/personalCenter/myForum',// 我的帖子
  myComment: baseUrl + '/personalCenter/myComment',// 我评论的帖子
  editForum: baseUrl + '/personalCenter/editForum',// 编辑帖子
  getForumsTypeAll: baseUrl + '/forum/getForumsTypeAll',// 获得论坛大厅列表
  formTotal: baseUrl + '/forum/formTotal',// 获得论坛头部展示数据
  getSeForum: baseUrl + '/forum/getSeForum',// 获得论坛二级列表数据
  getforumtypeByParentId: baseUrl + '/forum/getforumtypeByParentId',// 获得论坛二级分类
  
  // 课程
  getCurriculums: baseUrl + '/Curriculum/getCurriculums',//获得课程列表
  getCurriculumDetails: baseUrl + '/Curriculum/getCurriculumDetails',// 获得课程详情
  subscriptionCurriculum: baseUrl + '/Curriculum/subscriptionCurriculum',// 订阅//取消订阅课程
  getCurriculumSoftwareType: baseUrl + '/Curriculum/getCurriculumSoftwareType',// 获得课程软件分类
  getCurriculumTypeOne: baseUrl + '/Curriculum/getCurriculumTypeOne',// 获得课程分类列表一级
  getCurriculumTypeTwo: baseUrl + '/Curriculum/getCurriculumTypeTwo',// 获得课程分类列表二级
  whetherPurchaseCurriculum: baseUrl + '/Curriculum/whetherPurchaseCurriculum',// 判断是否购买课程
  insertCurriculumRecorde: baseUrl + '/Curriculum/insertCurriculumRecorde',// 新增课程记录
  getCurriculumLabel: baseUrl + '/Curriculum/getCurriculumLabel',// 获得课程标签
  createCurriculumOrder: baseUrl + '/aliPay/createCurriculumOrder',// 支付宝课程购买订单
  whetherPayCurriculum: baseUrl + '/Curriculum/whetherPayCurriculum',// 判断购买课程是否成功
  getPurchasedCurriculum: baseUrl + '/personalCenter/getPurchasedCurriculum',// 已购课程
  getLearningRecords: baseUrl + '/personalCenter/getLearningRecords',// 学习记录
  getCommonProblem: baseUrl + '/personalCenter/getCommonProblem',// 常见问题列表
  UnbindOpenid: baseUrl + '/personalCenter/UnbindOpenid',// 第三方解除绑定
  CustOutList: baseUrl + '/personalCenter/CustOutList',// 用户提现列表
  userCustOut: baseUrl + '/personalCenter/userCustOut',// 用户提现
  
  getSuccessCase: baseUrl + '/designer/getSuccessCase',// 获得成功案例
  getMaterial: baseUrl + '/designer/getMaterial',// 获得图纸作品
  whetherPaymentDownload: baseUrl + '/material/whetherPaymentDownload',// 判断是否支付下载
  examineDesignerFile: baseUrl + '/personalCenter/examineDesignerFile',// 驳回设计师提交文件
  getProportion: baseUrl + '/homePage/getProportion',// 积分充值比例
  
  createOrder: baseUrl + '/wxPay/createOrder',// 微信支付创建订单
  createOtherOrderB: baseUrl + '/wxPay/createOtherOrder',// 微信设计师支付保证金
  createIntegralOrderW: baseUrl + '/wxPay/createIntegralOrder',// 微信积分充值
  createCurriculumOrderK: baseUrl + '/wxPay/createCurriculumOrder',// 微信课程购买订单
  getTopMenu: baseUrl + '/homePage/getTopMenu',// 顶部菜单
  getAdvertisement: baseUrl + '/homePage/getAdvertisement',// 广告
  getTransactionsQuantity: baseUrl + '/homePage/getTransactionsQuantity',//平台交易总数据
  getCollection: baseUrl + '/taskHall/getCollection',//判断任务收藏
  getCollections: baseUrl + '/material/getCollection',//判断素材收藏
  publishCommentDetails: baseUrl + '/forum/publishCommentDetails',//回复
  
  getMeAttentionTask: baseUrl + '/personalCenter/getMeAttentionTask',//收藏任务
  getMeExclusive: baseUrl + '/personalCenter/getMeExclusive',//专属任务
  search: baseUrl + '/designerCompany/search',//搜索企业
  join: baseUrl + '/designerCompany/join',//加入企业
  add: baseUrl + '/designerCompany/add',//新建企业
  info: baseUrl + '/designerCompany/info',//我加入的企业
  update: baseUrl + '/designerCompany/update',//更新企业
  }