import api from '../index';
import urls from '../urls';

const header = {};

export default {
	getHomePageList (params) { // 登陆
	// return出去了一个promise
	return api.get(urls.getHomePageList, params, header);
	},
	getOnly (params) { // 获取首页运营数据
	// return出去了一个promise
	return api.get(urls.getOnly, params, header);
	},
	getTaskType (params) { // 获得任务分类
	// return出去了一个promise
	return api.get(urls.getTaskType, params, header);
	},
	getTaskDetails (params) { // 获得任务详情
	// return出去了一个promise
	return api.get(urls.getTaskDetails, params, header);
	},
	getProvince (params) { // 获得省份
	// return出去了一个promise
	return api.get(urls.getProvince, params, header);
	},
	getCity (params) { // 获得城市
	// return出去了一个promise
	return api.get(urls.getCity, params, header);
	},
	getArea(params) { // 获得区
	// return出去了一个promise
	return api.get(urls.getArea, params, header);
	},
	getTaskHallList (params) { // 任务大厅查询列表
	// return出去了一个promise
	return api.get(urls.getTaskHallList, params, header);
	},
	insertDemand (params) { // 发布需求
	// return出去了一个promise
	return api.post(urls.insertDemand, params, header);
	},
	getTasks (params) { // 获得最新任务列表
	// return出去了一个promise
	return api.get(urls.getTasks, params, header);
	},
	getQualifications (params) { // 判断是否有接单资格
	// return出去了一个promise
	return api.get(urls.getQualifications, params, header);
	},
	insertBidding (params) { // 竞标
	// return出去了一个promise
	return api.post(urls.insertBidding, params, header);
	},
	getDesigners (params) { // 获得设计师列表
	// return出去了一个promise
	return api.get(urls.getDesigners, params, header);
	},
	designerSort (params) { // 获得设计师列表（成交率排序）
	// return出去了一个promise
	return api.get(urls.designerSort, params, header);
	},
	getDesignerDetails (params) { // 获得设计师详情
	// return出去了一个promise
	return api.get(urls.getDesignerDetails, params, header);
	},
	attention (params) { // 关注/取消关注
	// return出去了一个promise
	return api.get(urls.attention, params, header);
	},
	getDesignerCase (params) { // 获得设计师案例
	// return出去了一个promise
	return api.get(urls.getDesignerCase, params, header);
	},
	insertDesigner (params) { // 入驻设计师
	// return出去了一个promise
	return api.post(urls.insertDesigner, params, header);
	},
	getCases (params) { // 获得成功案例列表
	// return出去了一个promise
	return api.get(urls.getCases, params, header);
	},
	getCaseDetails (params) { // 获得案例详情
	// return出去了一个promise
	return api.get(urls.getCaseDetails, params, header);
	},
	getWhetherDesigner (params) { // 判断是否为设计师
	// return出去了一个promise
	return api.get(urls.getWhetherDesigner, params, header);
	},
	getMePublishTasks (params) { // 获得我发布的任务列表
	// return出去了一个promise
	return api.get(urls.getMePublishTasks, params, header);
	},
	getMePublishTaskDetails (params) { // 获得我发布的任务详情
	// return出去了一个promise
	return api.get(urls.getMePublishTaskDetails, params, header);
	},
	choiceOffer (params) { // 同意或拒绝报价
	// return出去了一个promise
	return api.get(urls.choiceOffer, params, header);
	},
	getMeReceiveTasks (params) { // 获得我接收的任务列表
	// return出去了一个promise
	return api.get(urls.getMeReceiveTasks, params, header);
	},
	getMeReceiveTaskDetails (params) { // 获得我接收的任务详情
	// return出去了一个promise
	return api.get(urls.getMeReceiveTaskDetails, params, header);
	},
	editMeBidding (params) { // 修改我得报价
	// return出去了一个promise
	return api.post(urls.editMeBidding, params, header);
	},
	upload (params) { // 阿里云文件上传
	// return出去了一个promise
	return api.post(urls.upload, params, header);
	},
	getTaskBiddingCount (params) { // 获得任务参加报价人数
	// return出去了一个promise
	return api.get(urls.getTaskBiddingCount, params, header);
	},
	editDemand (params) { // 撤销任务
	// return出去了一个promise
	return api.get(urls.editDemand, params, header);
	},
	
	register (params) { // 注册
	// return出去了一个promise
	return api.post(urls.register, params, header);
	},
	login (params) { // 登陆
	// return出去了一个promise
	return api.post(urls.login, params, header);
	},
	logout (params) { // 退出登录
	// return出去了一个promise
	return api.get(urls.logout, params, header);
	},
	sendSms (params) { // 手机获取验证码
	// return出去了一个promise
	return api.get(urls.sendSms, params, header);
	},
	forgetPassword (params) { // 忘记密码
	// return出去了一个promise
	return api.post(urls.forgetPassword, params, header);
	},
	getCustCare (params) { // 获得客服
	// return出去了一个promise
	return api.get(urls.getCustCare, params, header);
	},
	
	createOrderZ (params) { // 支付宝支付
	// return出去了一个promise
	return api.post(urls.createOrderZ, params, header);
	},
	createOrderW (params) { // 微信支付
	// return出去了一个promise
	return api.post(urls.createOrderW, params, header);
	},
	collection (params) { // 收藏/取消
	// return出去了一个promise
	return api.get(urls.collection, params, header);
	},
	getUserAccountDetails (params) { //用户详情
	// return出去了一个promise
	return api.get(urls.getUserAccountDetails, params, header);
	},
	insertBiddingDetails (params) { //添加竞价付款节点信息
	// return出去了一个promise
	return api.get(urls.insertBiddingDetails, params, header);
	},
	UserPayment (params) { //用户支付节点
	// return出去了一个promise
	return api.get(urls.UserPayment, params, header);
	},
	getBiddingDetails (params) { //用户支付节点列表
	// return出去了一个promise
	return api.get(urls.getBiddingDetails, params, header);
	},
	getBiddingPay (params) { //竞价获取订单信息
	// return出去了一个promise
	return api.get(urls.getBiddingPay, params, header);
	},
	ysePayment (params) { //用户确认打款
	// return出去了一个promise
	return api.get(urls.ysePayment, params, header);
	},
	successTaskImg (params) { //成功上传文件
	// return出去了一个promise
	return api.post(urls.successTaskImg, params, header);
	},
	createOtherOrder (params) { //保证金
	// return出去了一个promise
	return api.post(urls.createOtherOrder, params, header);
	},
	
	
	getRotation (params) { //获得轮播图
	// return出去了一个promise
	return api.get(urls.getRotation, params, header);
	},
	getExplain (params) { //获得说明信息
	// return出去了一个promise
	return api.get(urls.getExplain, params, header);
	},
	getMaterials (params) { //获得素材列表
	// return出去了一个promise
	return api.get(urls.getMaterials, params, header);
	},
	getMaterialDetails (params) { //获得素材详情
	// return出去了一个promise
	return api.get(urls.getMaterialDetails, params, header);
	},
	getDrawingDetails (params) { //获得图纸详情
	// return出去了一个promise
	return api.get(urls.getDrawingDetails, params, header);
	},
	attentionDrawing (params) { //关注//取消关注素材
	// return出去了一个promise
	return api.get(urls.attentionDrawing, params, header);
	},
	getSoftwareType (params) { //获得软件分类列表
	// return出去了一个promise
	return api.get(urls.getSoftwareType, params, header);
	},
	getDrawingTypeOne (params) { //获得图纸分类列表一级
	// return出去了一个promise
	return api.get(urls.getDrawingTypeOne, params, header);
	},
	getDrawingTypeTwo (params) { //获得图纸分类列表二级
	// return出去了一个promise
	return api.get(urls.getDrawingTypeTwo, params, header);
	},
	getDrawingTypeThree (params) { //获得图纸分类列表三级
	// return出去了一个promise
	return api.get(urls.getDrawingTypeThree, params, header);
	},
	intergralRecharge (params) { //获取积分充值列表
	// return出去了一个promise
	return api.get(urls.intergralRecharge, params, header);
	},
	paymentDownload (params) { //支付下载
	// return出去了一个promise
	return api.get(urls.paymentDownload, params, header);
	},
	insertMaterial (params) { //上传素材
	// return出去了一个promise
	return api.post(urls.insertMaterial, params, header);
	},
	getMaterialsM (params) { //获得我的素材列表
	// return出去了一个promise
	return api.get(urls.getMaterialsM, params, header);
	},
	getMaterialDetails (params) { //获得我的素材详情
	// return出去了一个promise
	return api.get(urls.getMaterialDetails, params, header);
	},
	editMaterial (params) { //编辑/删除素材
	// return出去了一个promise
	return api.post(urls.editMaterial, params, header);
	},
	getMaterialDownloads (params) { //获得我下载记录
	// return出去了一个promise
	return api.get(urls.getMaterialDownloads, params, header);
	},
	getMeAttention (params) { //获得我的收藏
	// return出去了一个promise
	return api.get(urls.getMeAttention, params, header);
	},
	getUserDetails (params) { //获得个人中心信息
	// return出去了一个promise
	return api.get(urls.getUserDetails, params, header);
	},
	editUser (params) { //修改账号信息
	// return出去了一个promise
	return api.get(urls.editUser, params, header);
	},
	editDesigner (params) { //修改设计师信息
	// return出去了一个promise
	return api.post(urls.editDesigner, params, header);
	},
	editReal (params) { //实名认证
	// return出去了一个promise
	return api.post(urls.editReal, params, header);
	},
	getIntergralRecord (params) { //获得积分币记录
	// return出去了一个promise
	return api.get(urls.getIntergralRecord, params, header);
	},
	getMaterialRecord (params) { //获得购买素材记录
	// return出去了一个promise
	return api.get(urls.getMaterialRecord, params, header);
	},
	getCurriculumRecord (params) { //获得购买教育记录
	// return出去了一个promise
	return api.get(urls.getCurriculumRecord, params, header);
	},
	getDemandRecord (params) { //获得任务记录
	// return出去了一个promise
	return api.get(urls.getDemandRecord, params, header);
	},
	getWhetherDesigner (params) { //判断是否为设计师
	// return出去了一个promise
	return api.get(urls.getWhetherDesigner, params, header);
	},
	getTaskPay (params) { //获得任务支付赏金页面
	// return出去了一个promise
	return api.get(urls.getTaskPay, params, header);
	},
	createIntegralOrder (params) { //支付宝积分充值
	// return出去了一个promise
	return api.post(urls.createIntegralOrder, params, header);
	},
	getPaySu (params) { //获得订单成功失败
	// return出去了一个promise
	return api.get(urls.getPaySu, params, header);
	},
	getBountyPay (params) { //托管获得支付页面
	// return出去了一个promise
	return api.get(urls.getBountyPay, params, header);
	},
	bountyPay (params) { //支付托管赏金
	// return出去了一个promise
	return api.get(urls.bountyPay, params, header);
	},
	designerPay (params) { //支付设计师赏金
	// return出去了一个promise
	return api.get(urls.designerPay, params, header);
	},
	bountyPays (params) { //托管支付设计师赏金
	// return出去了一个promise
	return api.get(urls.bountyPays, params, header);
	},
	submitFile (params) { //设计师提交任务附件
	// return出去了一个promise
	return api.post(urls.submitFile, params, header);
	},
	submitCase (params) { //设计师提交案例
	// return出去了一个promise
	return api.get(urls.submitCase, params, header);
	},
	getDesignerPay (params) { //获得支付设计师赏金页面
	// return出去了一个promise
	return api.get(urls.getDesignerPay, params, header);
	},
	
	getUserDetails (params) { //获得个人中心信息
	// return出去了一个promise
	return api.get(urls.getUserDetails, params, header);
	},
	getMyProfit (params) { //获得我的收益
	// return出去了一个promise
	return api.get(urls.getMyProfit, params, header);
	},
	editUser (params) { //修改账号信息
	// return出去了一个promise
	return api.post(urls.editUser, params, header);
	},
	lookMessage (params) { //消息列表
	// return出去了一个promise
	return api.get(urls.lookMessage, params, header);
	},
	signIn (params) { //签到
	// return出去了一个promise
	return api.get(urls.signIn, params, header);
	},
	getSignIn (params) { //获得签到数据
	// return出去了一个promise
	return api.get(urls.getSignIn, params, header);
	},
	deleteBidding (params) { //取消报价
	// return出去了一个promise
	return api.get(urls.deleteBidding, params, header);
	},
	getGoodSoftware (params) { //获得擅长软件下拉框
	// return出去了一个promise
	return api.get(urls.getGoodSoftware, params, header);
	},
	wxLogin (params) { //微信登录二维码
	// return出去了一个promise
	return api.get(urls.wxLogin, params, header);
	},
	qqLogin (params) { //qq登录二维码
	// return出去了一个promise
	return api.get(urls.qqLogin, params, header);
	},
	wxCallback (params) { //微信回调
	// return出去了一个promise
	return api.get(urls.wxCallback, params, header);
	},
	readMessage (params) { //已读
	// return出去了一个promise
	return api.get(urls.readMessage, params, header);
	},
	editUserPhone (params) { //绑定手机号
	// return出去了一个promise
	return api.post(urls.editUserPhone, params, header);
	},
	requestPayment (params) { //请求打款
	// return出去了一个promise
	return api.get(urls.requestPayment, params, header);
	},
	// 论坛
	getForum (params) { //获得论坛大厅列表
	// return出去了一个promise
	return api.get(urls.getForum, params, header);
	},
	getForumDetails (params) { //获得帖子详情
	// return出去了一个promise
	return api.get(urls.getForumDetails, params, header);
	},
	publishForum (params) { //发表帖子
	// return出去了一个promise
	return api.post(urls.publishForum, params, header);
	},
	publishComment (params) { //发表评论
	// return出去了一个promise
	return api.post(urls.publishComment, params, header);
	},
	getForumType (params) { //获得论坛分类
	// return出去了一个promise
	return api.get(urls.getForumType, params, header);
	},
	myForum (params) { //我的帖子
	// return出去了一个promise
	return api.get(urls.myForum, params, header);
	},
	myComment (params) { //我评论的帖子
	// return出去了一个promise
	return api.get(urls.myComment, params, header);
	},
	editForum (params) { //编辑帖子
	// return出去了一个promise
	return api.post(urls.editForum, params, header);
	},
	getForumsTypeAll (params) { //获得论坛大厅列表
	// return出去了一个promise
	return api.get(urls.getForumsTypeAll, params, header);
	},
	formTotal (params) { //获得论坛头部展示数据
	// return出去了一个promise
	return api.get(urls.formTotal, params, header);
	},
	getSeForum (params) { //获得论坛二级列表数据
	// return出去了一个promise
	return api.get(urls.getSeForum, params, header);
	},
	getforumtypeByParentId(params) { //获得论坛二级分类
	// return出去了一个promise
	return api.get(urls.getforumtypeByParentId, params, header);
	},
	//课程
	getCurriculums (params) { //获得课程列表
	// return出去了一个promise
	return api.get(urls.getCurriculums, params, header);
	},
	getCurriculumDetails (params) { //获得课程详情
	// return出去了一个promise
	return api.get(urls.getCurriculumDetails, params, header);
	},
	subscriptionCurriculum (params) { //订阅//取消订阅课程
	// return出去了一个promise
	return api.get(urls.subscriptionCurriculum, params, header);
	},
	getCurriculumSoftwareType (params) { //获得课程软件分类
	// return出去了一个promise
	return api.get(urls.getCurriculumSoftwareType, params, header);
	},
	getCurriculumTypeOne (params) { //获得课程分类列表一级
	// return出去了一个promise
	return api.get(urls.getCurriculumTypeOne, params, header);
	},
	getCurriculumTypeTwo (params) { //获得课程分类列表二级
	// return出去了一个promise
	return api.get(urls.getCurriculumTypeTwo, params, header);
	},
	whetherPurchaseCurriculum (params) { //判断是否购买课程
	// return出去了一个promise
	return api.get(urls.whetherPurchaseCurriculum, params, header);
	},
	insertCurriculumRecorde (params) { //新增课程记录
	// return出去了一个promise
	return api.post(urls.insertCurriculumRecorde, params, header);
	},
	getCurriculumLabel (params) { //获得课程标签
	// return出去了一个promise
	return api.get(urls.getCurriculumLabel, params, header);
	},
	createCurriculumOrder (params) { //支付宝课程购买订单
	// return出去了一个promise
	return api.post(urls.createCurriculumOrder, params, header);
	},
	whetherPayCurriculum (params) { //判断购买课程是否成功
	// return出去了一个promise
	return api.get(urls.whetherPayCurriculum, params, header);
	},
	getPurchasedCurriculum (params) { //已购课程
	// return出去了一个promise
	return api.get(urls.getPurchasedCurriculum, params, header);
	},
	getLearningRecords (params) { //学习记录
	// return出去了一个promise
	return api.get(urls.getLearningRecords, params, header);
	},
	getCommonProblem (params) { //常见问题列表
	// return出去了一个promise
	return api.get(urls.getCommonProblem, params, header);
	},
	UnbindOpenid (params) { //第三方解除绑定
	// return出去了一个promise
	return api.get(urls.UnbindOpenid, params, header);
	},
	CustOutList (params) { //用户提现列表
	// return出去了一个promise
	return api.get(urls.CustOutList, params, header);
	},
	userCustOut (params) { //用户提现
	// return出去了一个promise
	return api.post(urls.userCustOut, params, header);
	},
	
	getSuccessCase (params) { //获得成功案例
	// return出去了一个promise
	return api.get(urls.getSuccessCase, params, header);
	},
	getMaterial (params) { //获得图纸作品
	// return出去了一个promise
	return api.get(urls.getMaterial, params, header);
	},
	whetherPaymentDownload (params) { //判断是否支付下载
	// return出去了一个promise
	return api.get(urls.whetherPaymentDownload, params, header);
	},
	examineDesignerFile (params) { //驳回设计师提交文件
	// return出去了一个promise
	return api.get(urls.examineDesignerFile, params, header);
	},
	getProportion (params) { //积分充值比例
	// return出去了一个promise
	return api.get(urls.getProportion, params, header);
	},
	
	createOrder (params) { //微信支付创建订单
	// return出去了一个promise
	return api.post(urls.createOrder, params, header);
	},
	createOtherOrderB (params) { //微信设计师支付保证金
	// return出去了一个promise
	return api.post(urls.createOtherOrderB, params, header);
	},
	createIntegralOrderW (params) { //微信积分充值
	// return出去了一个promise
	return api.post(urls.createIntegralOrderW, params, header);
	},
	createCurriculumOrderK (params) { //微信课程购买订单
	// return出去了一个promise
	return api.post(urls.createCurriculumOrderK, params, header);
	},
	getTopMenu (params) { //顶部菜单
	// return出去了一个promise
	return api.get(urls.getTopMenu, params, header);
	},
	getAdvertisement (params) { //广告
	// return出去了一个promise
	return api.get(urls.getAdvertisement, params, header);
	},
	
	getCollection (params) { //判断任务收藏
	// return出去了一个promise
	return api.get(urls.getCollection, params, header);
	},
	getCollections (params) { //判断素材收藏
	// return出去了一个promise
	return api.get(urls.getCollections, params, header);
	},
	publishCommentDetails (params) { //回复
	// return出去了一个promise
	return api.post(urls.publishCommentDetails, params, header);
	},
	
	getMeAttentionTask (params) { //收藏任务
	// return出去了一个promise
	return api.get(urls.getMeAttentionTask, params, header);
	},
	getMeExclusive (params) { //专属任务
	// return出去了一个promise
	return api.get(urls.getMeExclusive, params, header);
	},
	search (params) { //搜索企业
	// return出去了一个promise
	return api.get(urls.search, params, header);
	},
	join (params) { //加入企业
	// return出去了一个promise
	return api.get(urls.join, params, header);
	},
	add (params) { //加入企业
	// return出去了一个promise
	return api.post(urls.add, params, header);
	},
	info (params) { //加入企业
	// return出去了一个promise
	return api.get(urls.info, params, header);
	},
	update (params) { //更新企业
	// return出去了一个promise
	return api.post(urls.update, params, header);
    },
    getTransactionsQuantity (params) { //更新企业
        // return出去了一个promise
        return api.get(urls.getTransactionsQuantity, params, header);
    },
};
