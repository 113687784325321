import axios from './axios';

let instance = axios();
export default {
  get (url, params, headers) {
    let options = {};
    if (params) {
      options.params = params;
    }
	const token = window.localStorage.getItem('token')
   if (token) {
   	options.headers = {
   		//'Authorization': 'Bearer ' + token,
   		'autha':token,
		
   	};
   } 
    return instance.get(url, options);
  },
  post (url, params, headers) {
    let options = {};
    const token = window.localStorage.getItem('token')
    if (token) {
    	options.headers = {
    		//'Authorization': 'Bearer ' + token,
    		'autha':token
    	};
    } 
    return instance.post(url, params, options);
  },
  put (url, params, headers) {
    let options = {};
    if (headers) {
      options.headers = headers;
    }
    return instance.put(url, params, options);
  },
  delete (url, params, headers) {
    let options = {};
    if (params) {
      options.params = params;
    }
    if (headers) {
      options.headers = headers;
    }
    return instance.delete(url, options);
  }
};
