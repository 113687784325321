import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  searchList:[{name:'素材',id:0},{name:'任务',id:1},{name:'课程',id:2}],
	  loginShow:false,
	  showXu:null,
	  phone:'',
	  explan:[],
	  qian:true,
	  biLi:''
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
