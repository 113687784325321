function reSize(){
    var deviceWidth = window.innerWidth || document.documentElement.clientWidth;
    // if(deviceWidth>=750){
    //     deviceWidth = 750
    // }
    // if(deviceWidth<=320){
    //     deviceWidth = 320
    // }
    document.documentElement.style.fontSize = (deviceWidth/19.2)+'px'
     document.body.style.fontSize = 0.18+'rem'
    //设计稿是750px
    //设置一半的宽度，那么就是375px
    //1rem == 100px的设计稿宽度
    //表达一半的宽度就是3.75rem
}
reSize()
window.onresize = function(){
	console.log(1111111111);
    reSize()
}