import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import api from './api/install';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import './utils/rem.js';
import {baseUrl} from './api/urls'
import clipboard from 'clipboard'
Vue.prototype.clipboard = clipboard;
Vue.use(VueQuillEditor);
Vue.use(ElementUI);
Vue.use(api);
Vue.prototype.$axios = axios;
Vue.prototype.baseUrl = baseUrl
Vue.config.productionTip = false
router.afterEach((to,from,next) => {
	document.body.scrollTop =0;
	document.documentElement.scrollTop = 0;
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
