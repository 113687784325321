import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index/Index.vue')
  },
  {
    path: '/Task',
    name: 'Task',
    component: () => import(/* webpackChunkName: "about" */ '../views/Task/Task.vue')
  },
  {
    path: '/Publish',
    name: 'Publish',
    component: () => import(/* webpackChunkName: "about" */ '../views/Publish/Publish.vue')
  },
  {
    path: '/Payment',
    name: 'Payment',
    component: () => import(/* webpackChunkName: "about" */ '../views/Payment/Payment.vue')
  },
  {
    path: '/Succeed',
    name: 'Succeed',
    component: () => import(/* webpackChunkName: "about" */ '../views/Succeed/Succeed.vue')
  },
  {
    path: '/Become',
    name: 'Become',
    component: () => import(/* webpackChunkName: "about" */ '../views/Become/Become.vue')
  },
  {
    path: '/Enter',
    name: 'Enter',
    component: () => import(/* webpackChunkName: "about" */ '../views/Enter/Enter.vue')
  },
  {
    path: '/EnterCom',
    name: 'EnterCom',
    component: () => import(/* webpackChunkName: "about" */ '../views/EnterCom/EnterCom.vue')
  },
  {
    path: '/ComCert',
    name: 'ComCert',
    component: () => import(/* webpackChunkName: "about" */ '../views/ComCert/ComCert.vue')
  },
  {
    path: '/ComExamine',
    name: 'ComExamine',
    component: () => import(/* webpackChunkName: "about" */ '../views/ComExamine/ComExamine.vue')
  },
  {
    path: '/Earnest',
    name: 'Earnest',
    component: () => import(/* webpackChunkName: "about" */ '../views/Earnest/Earnest.vue')
  },
  {
    path: '/Esucceed',
    name: 'Esucceed',
    component: () => import(/* webpackChunkName: "about" */ '../views/Esucceed/Esucceed.vue')
  },
  {
    path: '/Case',
    name: 'Case',
    component: () => import(/* webpackChunkName: "about" */ '../views/Case/Case.vue')
  },
  {
    path: '/CaseDetails',
    name: 'CaseDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/CaseDetails/CaseDetails.vue')
  },
  {
    path: '/Find',
    name: 'Find',
    component: () => import(/* webpackChunkName: "about" */ '../views/Find/Find.vue')
  },
  {
    path: '/Brief',
    name: 'Brief',
    component: () => import(/* webpackChunkName: "about" */ '../views/Brief/Brief.vue')
  },
  {
    path: '/Hire',
    name: 'Hire',
    component: () => import(/* webpackChunkName: "about" */ '../views/Hire/Hire.vue')
  },
  {
    path: '/MyTask',
    name: 'MyTask',
    component: () => import(/* webpackChunkName: "about" */ '../views/MyTask/MyTask.vue')
  },
  {
    path: '/TackTask',
    name: 'TackTask',
    component: () => import(/* webpackChunkName: "about" */ '../views/TackTask/TackTask.vue')
  },
  {
    path: '/MyTaskDet',
    name: 'MyTaskDet',
    component: () => import(/* webpackChunkName: "about" */ '../views/MyTaskDet/MyTaskDet.vue')
  },
  {
    path: '/ShouTask',
    name: 'ShouTask',
    component: () => import(/* webpackChunkName: "about" */ '../views/ShouTask/ShouTask.vue')
  },
  {
    path: '/MyTaskFu',
    name: 'MyTaskFu',
    component: () => import(/* webpackChunkName: "about" */ '../views/MyTaskFu/MyTaskFu.vue')
  },
  {
    path: '/MyTaskWan',
    name: 'MyTaskWan',
    component: () => import(/* webpackChunkName: "about" */ '../views/MyTaskWan/MyTaskWan.vue')
  },
  {
    path: '/MyTaskBo',
    name: 'MyTaskBo',
    component: () => import(/* webpackChunkName: "about" */ '../views/MyTaskBo/MyTaskBo.vue')
  },
  {
    path: '/MyTaskShen',
    name: 'MyTaskShen',
    component: () => import(/* webpackChunkName: "about" */ '../views/MyTaskShen/MyTaskShen.vue')
  },
  {
    path: '/TackBao',
    name: 'TackBao',
    component: () => import(/* webpackChunkName: "about" */ '../views/TackBao/TackBao.vue')
  },
  {
    path: '/SetTack',
    name: 'SetTack',
    component: () => import(/* webpackChunkName: "about" */ '../views/SetTack/SetTack.vue')
  },
  {
    path: '/TackFu',
    name: 'TackFu',
    component: () => import(/* webpackChunkName: "about" */ '../views/TackFu/TackFu.vue')
  },
  {
    path: '/TackWan',
    name: 'TackWan',
    component: () => import(/* webpackChunkName: "about" */ '../views/TackWan/TackWan.vue')
  },
  {
    path: '/TackBo',
    name: 'TackBo',
    component: () => import(/* webpackChunkName: "about" */ '../views/TackBo/TackBo.vue')
  }, 
  {
    path: '/TackShen',
    name: 'TackShen',
    component: () => import(/* webpackChunkName: "about" */ '../views/TackShen/TackShen.vue')
  },
  {
    path: '/Lobby',
    name: 'Lobby',
    component: () => import(/* webpackChunkName: "about" */ '../views/Lobby/Lobby.vue')
  },
  {
    path: '/TaskDet',
    name: 'TaskDet',
    component: () => import(/* webpackChunkName: "about" */ '../views/TaskDet/TaskDet.vue')
  },
  {
    path: '/MyInfo',
    name: 'MyInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/MyInfo/MyInfo.vue')
  },
  {
    path: '/Competitive',
    name: 'Competitive',
    component: () => import(/* webpackChunkName: "about" */ '../views/Competitive/Competitive.vue')
  },
  {
    path: '/TaskGuo',
    name: 'TaskGuo',
    component: () => import(/* webpackChunkName: "about" */ '../views/TaskGuo/TaskGuo.vue')
  },
  {
    path: '/Matter',
    name: 'Matter',
    component: () => import(/* webpackChunkName: "about" */ '../views/Matter/Matter.vue')
  },
  {
    path: '/Matter/matterDet',
    name: 'matterDet',
    component: () => import(/* webpackChunkName: "about" */ '../views/Matter/matterDet.vue')
  },
  {
    path: '/Matter/downloadM',
    name: 'downloadM',
    component: () => import(/* webpackChunkName: "about" */ '../views/Matter/downloadM.vue')
  },
  {
    path: '/Matter/downloadS',
    name: 'downloadS',
    component: () => import(/* webpackChunkName: "about" */ '../views/Matter/downloadS.vue')
  },
  {
    path: '/Matter/downloadD',
    name: 'downloadD',
    component: () => import(/* webpackChunkName: "about" */ '../views/Matter/downloadD.vue')
  },
  {
    path: '/Matter/recharge',
    name: 'recharge',
    component: () => import(/* webpackChunkName: "about" */ '../views/Matter/recharge.vue')
  },
  {
    path: '/Forum',
    name: 'Forum',
    component: () => import(/* webpackChunkName: "about" */ '../views/Forum/Forum.vue'),
	children:[
		{
		    path: '/Forum/ForumSecord',
		    name: 'ForumSecord',
		    component: () => import(/* webpackChunkName: "ForumSecord" */ '@/views/Forum/ForumSecord.vue')
		},
		{
		    path: '/Forum/ForumFirst',
		    name: 'ForumFirst',
		    component: () => import(/* webpackChunkName: "ForumFirst" */ '@/views/Forum/ForumFirst.vue')
		}
	]
  },
  {
    path: '/Forum/faForum',
    name: 'faForum',
    component: () => import(/* webpackChunkName: "about" */ '../views/Forum/faForum.vue')
  },
  {
    path: '/Forum/forumDet',
    name: 'forumDet',
    component: () => import(/* webpackChunkName: "about" */ '../views/Forum/forumDet.vue')
  },
  {
    path: '/Education',
    name: 'Education',
    component: () => import(/* webpackChunkName: "about" */ '../views/Education/education.vue')
  },
  {
    path: '/Education/educationDet',
    name: 'educationDet',
    component: () => import(/* webpackChunkName: "about" */ '../views/Education/educationDet.vue')
  },
  {
    path: '/Education/educationM',
    name: 'educationM',
    component: () => import(/* webpackChunkName: "about" */ '../views/Education/educationM.vue')
  },
  {
    path: '/Education/educationS',
    name: 'educationS',
    component: () => import(/* webpackChunkName: "about" */ '../views/Education/educationS.vue')
  },
  {
    path: '/Education/recharge',
    name: 'recharge',
    component: () => import(/* webpackChunkName: "about" */ '../views/Education/recharge.vue')
  },
  // {
  //   path: '/Education/educationAll',
  //   name: 'educationAll',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Education/educationAll.vue')
  // },
  // {
  //   path: '/Education/educationNew',
  //   name: 'educationNew',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Education/educationNew.vue')
  // },
  // {
  //   path: '/Education/educationHigh',
  //   name: 'educationHigh',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Education/educationHigh.vue')
  // },
  {
    path: '/Education/educationFree',
    name: 'educationFree',
    component: () => import(/* webpackChunkName: "about" */ '../views/Education/educationFree.vue')
  },
  {
    path: '/Education/educationV',
    name: 'educationV',
    component: () => import(/* webpackChunkName: "about" */ '../views/Education/educationV.vue')
  },
  {
    path: '/Education/educationP',
    name: 'educationP',
    component: () => import(/* webpackChunkName: "about" */ '../views/Education/educationP.vue')
  },
  {
    path: '/data/dataInfo',
    name: 'dataInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataInfo.vue')
  },
  {
    path: '/data/dataId',
    name: 'dataId',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataId.vue')
  },
  {
    path: '/data/dataCen',
    name: 'dataCen',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataCen.vue')
  },
  {
    path: '/data/dataCom',
    name: 'dataCom',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataCom.vue')
  },
  {
    path: '/data/dataJie',
    name: 'dataJie',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataJie.vue')
  },
  {
    path: '/data/dataRen',
    name: 'dataRen',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataRen.vue')
  },
  {
    path: '/data/dataPing',
    name: 'dataPing',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataPing.vue')
  },
  {
    path: '/data/dataYong',
    name: 'dataYong',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataYong.vue')
  },
  {
    path: '/data/dataJiao',
    name: 'dataJiao',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataJiao.vue')
  },
  {
    path: '/data/dataFuC',
    name: 'dataFuC',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataFuC.vue')
  },
  {
    path: '/data/dataTou',
    name: 'dataTou',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataTou.vue')
  },
  {
    path: '/data/dataZhuan',
    name: 'dataZhuan',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataZhuan.vue')
  },
  {
    path: '/data/dataShou',
    name: 'dataShou',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataShou.vue')
  },
  {
    path: '/data/dataFaX',
    name: 'dataFaX',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataFaX.vue')
  },
  {
    path: '/data/dataChong',
    name: 'dataChong',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataChong.vue')
  },
  {
    path: '/data/dataUp',
    name: 'dataUp',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataUp.vue')
  },
  {
    path: '/data/dataSu',
    name: 'dataSu',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataSu.vue')
  },
  {
    path: '/data/dataJi',
    name: 'dataJi',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataJi.vue')
  },
  {
    path: '/data/dataCang',
    name: 'dataCang',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataCang.vue')
  },
  {
    path: '/data/dataXue',
    name: 'dataXue',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataXue.vue')
  },
  {
    path: '/data/dataKe',
    name: 'dataKe',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataKe.vue')
  },
  {
    path: '/data/dataFa',
    name: 'dataFa',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataFa.vue')
  },
  {
    path: '/data/dataLun',
    name: 'dataLun',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataLun.vue')
  },
  {
    path: '/data/dataZhi',
    name: 'dataZhi',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataZhi.vue')
  },
  {
    path: '/data/dataLu',
    name: 'dataLu',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataLu.vue')
  },
  {
    path: '/data/dataQuan',
    name: 'dataQuan',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataQuan.vue')
  },
  {
    path: '/data/dataXian',
    name: 'dataXian',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataXian.vue')
  },
  {
    path: '/data/dataTi',
    name: 'dataTi',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataTi.vue')
  },
  {
    path: '/data/dataQian',
    name: 'dataQian',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataQian.vue')
  },
  {
    path: '/data/dataGao',
    name: 'dataGao',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataGao.vue')
  },
  {
    path: '/data/dataCheng',
    name: 'dataCheng',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataCheng.vue')
  },
  {
    path: '/data/dataTop',
    name: 'dataTop',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataTop.vue')
  },
  {
    path: '/data/dataTops',
    name: 'dataTops',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataTops.vue')
  },
  {
    path: '/mine/mineTop1',
    name: 'mineTop1',
    component: () => import(/* webpackChunkName: "about" */ '../views/mine/mineTop1.vue')
  },
  {
    path: '/mine/mineUp',
    name: 'mineUp',
    component: () => import(/* webpackChunkName: "about" */ '../views/mine/mineUp.vue')
  },
  {
    path: '/mine/mineUps',
    name: 'mineUps',
    component: () => import(/* webpackChunkName: "about" */ '../views/mine/mineUps.vue')
  },
  {
    path: '/mine/mineUps1',
    name: 'mineUps1',
    component: () => import(/* webpackChunkName: "about" */ '../views/mine/mineUps1.vue')
  },
  {
    path: '/mine/mineZhi',
    name: 'mineZhi',
    component: () => import(/* webpackChunkName: "about" */ '../views/mine/mineZhi.vue')
  },
  {
    path: '/mine/mineCheng',
    name: 'mineCheng',
    component: () => import(/* webpackChunkName: "about" */ '../views/mine/mineCheng.vue')
  },
  {
    path: '/data/dataUps',
    name: 'dataUps',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataUps.vue')
  },
  {
    path: '/mine/mineTui',
    name: 'mineTui',
    component: () => import(/* webpackChunkName: "about" */ '../views/mine/mineTui.vue')
  },
  {
    path: '/mine/mineWei',
    name: 'mineWei',
    component: () => import(/* webpackChunkName: "about" */ '../views/mine/mineWei.vue')
  },
  {
    path: '/mine/mineZai',
    name: 'mineZai',
    component: () => import(/* webpackChunkName: "about" */ '../views/mine/mineZai.vue')
  },
  {
    path: '/data/dataLian',
    name: 'dataLian',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataLian.vue')
  },
  {
    path: '/data/dataZhu',
    name: 'dataZhu',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataZhu.vue')
  },
  {
    path: '/data/dataYin',
    name: 'dataYin',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataYin.vue')
  },
  {
    path: '/data/dataChang',
    name: 'dataChang',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataChang.vue')
  },
  {
    path: '/data/dataXiao',
    name: 'dataXiao',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataXiao.vue')
  },
  {
    path: '/data/dataXi',
    name: 'dataXi',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataXi.vue')
  },
  {
    path: '/data/dataBang',
    name: 'dataBang',
    component: () => import(/* webpackChunkName: "about" */ '../views/data/dataBang.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
